import React from "react"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import FreeTrialFeatures from "../../components/free-trial/free-trial-features"
import Notification from "../../components/base/notification"

const FreeTrialConfirmation = () => {
  return (
    <div className="max-h-screen min-h-screen pt-8 overflow-hidden bg-qiwio-bg-black">
      <Seo title="Start your free trial of Qiwio" />

      <header className="flex justify-end px-20 translate-y-10 max-lg:justify-start max-lg:px-8">
        <Link to="/">
          <StaticImage
            src="../../images/logos/Qiwio Logo.svg"
            alt="Qiwio logo"
            loading="eager"
            width={100}
            placeholder="none"
          />
        </Link>
      </header>

      <main className="relative flex justify-center max-lg:mt-32">
        <div className="flex">
          <FreeTrialFeatures />

          <div className="pl-20 flex flex-col justify-center -translate-y-20 min-w-[60vw] max-lg:w-full max-lg:px-0 max-lg:translate-y-0">
            <Notification
              title="Form successfully sent!"
              message="A member of our sales team will contact you within 2-5 working days to help onboard you into Qiwio and make sure your free trial is a success"
            />

            <Link to="/" className="mt-4 text-base font-medium text-white">
              Go back home
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </main>
    </div>
  )
}

export default FreeTrialConfirmation
