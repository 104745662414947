import React from 'react'

const FeatureListItem = ({children, featureTitle,}) => {
  return (
    <div className='flex items-center'>
        {children}
        <p className='font-medium'>{featureTitle}</p>
    </div>
  )
}

export default FeatureListItem