import React from "react";

import { CheckCircleIcon } from '@heroicons/react/24/outline'

const Notification = ({title, message}) => {
    return (
        <div className="w-full max-lg:min-w-full">
            <div aria-live="assertive" className="inset-0 flex pointer-events-none sm:items-start">
                <div className="flex flex-col w-full space-y-4">
                    <div className="w-full max-w-sm overflow-hidden rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 bg-qiwio-field-bg">
                        <div className="flex items-start p-4">
                            <div className="flex-shrink-0 pt-0.5">
                                <CheckCircleIcon className="w-6 h-6 text-qiwio-green" aria-hidden="true" />
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="font-medium text-white text-md">{title}</p>
                                <p className="mt-1 text-sm text-white/60">{message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Notification