import React from "react"
import FeatureListItem from "./feature-list-item"

import { Square3Stack3DIcon } from "@heroicons/react/24/outline"
import { AiOutlineBranches } from "@react-icons/all-files/ai/AiOutlineBranches"
import { FiBox } from "@react-icons/all-files/fi/FiBox"
import { BsBoundingBoxCircles } from "@react-icons/all-files/bs/BsBoundingBoxCircles.esm"

export default function FreeTrialFeatures() {
  return (
    <div className="hidden min-h-screen px-20 min-w-[40vw] lg:relative lg:block lg:flex-1 gradient-animation -translate-y-18">
      <div className="pt-[35vh]">
        <h2>Create shoppable content</h2>

        <div className="flex flex-col gap-6 py-10">
          <FeatureListItem featureTitle="Create interactive 'shoppable' photos & videos">
            <Square3Stack3DIcon className="w-10 h-10 mr-10 text-white" />
          </FeatureListItem>
          <FeatureListItem featureTitle="Interactive content editor">
            <BsBoundingBoxCircles className="w-10 h-10 p-1 mr-10 text-white" />
          </FeatureListItem>
          <FeatureListItem featureTitle="Video branching">
            <AiOutlineBranches className="w-10 h-10 mr-10 text-white" />
          </FeatureListItem>
          <FeatureListItem featureTitle="PIM integrations">
            <FiBox className="w-10 h-10 mr-10 text-white" />
          </FeatureListItem>
        </div>
      </div>
    </div>
  )
}
